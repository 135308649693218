.modal-50w {
  width: 47% !important;
  height: 450px !important;
}

.modal-50w .modal-content .modal-body {
  overflow: hidden !important;
}

.modal-50w .modal-content .modal-header {
  justify-content: center;
  align-items: center;
}
