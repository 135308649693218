.thumb {
    color: #cbcbcb;
    background-color: #cbcbcb !important;
    border: none;
    /* width: 8px !important;
    height: 8px !important; */
  }
  
  .thumb::before {
    border: none !important;
    box-shadow: none !important;

  }
  
  .bar-right,
  .bar-inner,
  .bar-left {
    box-shadow: none !important;
    background-color: #cbcbcb !important;
    border: none !important;
  }
  