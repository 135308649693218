.FL__container {
  background-color: #bde0ff;
  color: black;
  border-radius: 12px;
  padding: 3px 10px;
  width: fit-content;
  margin: 2px 2px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.FL__container-filtered {
  background-color: #cbcbcb;
  color: black;

  border-radius: 12px;
  padding: 3px 10px;
  width: fit-content;
  margin: 2px 2px;
  cursor: pointer;
  font-size: 14px;
}

.TF__FilterContainer {
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
}

.TF__Container {
  margin: 8px 4px;
}

.TF__Container label {
  margin-left: 2px;
  font-weight: bold;
  /* border-bottom: 1px solid #cbcbcb; */
  margin-bottom: 4px;
}

.minMax-container {
  display: flex;
  flex-direction: column;
}

.minMax-container label {
  font-weight: normal;
}

.minMax-inputs {
  display: flex;
  /* max-width: 100%; */
}

.min-max-input {
  /* max-width: 40%; */
  width: 50px;
  border: 0.5px solid #cbcbcb;
  border-radius: 4px;
  margin-right: 8px;
  padding: 6px 8px;
  margin-top: 4px;
  margin-bottom: 6px;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.errored-input {
  border: 1px solid red !important  ;
}


/* react-slider */

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;
  border: 1px solid grey;
}

.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border: 5px solid gray;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-1 {
  background: #f00;
}

.example-track.example-track-2 {
  background: #0f0;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 10px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 50px;
  height: 48px;
  line-height: 38px;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

/* react-slider */