.non-block {
  display: block !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.non-block > .dropdown-menu {
  right: auto !important;
  left: 0 !important;
}

.extender {
  display: flex;
}

@media only screen and (min-width: 1200px) {
  .non-block {
    display: none !important;
  }

  .extender {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .extender {
    display: none !important;
  }
}
