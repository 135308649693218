.custom-slider-container {
    display: flex;
    align-items: center;
  }
  
  .custom-slider {
    width: 100%;
    appearance: none;
    height: 5px;
    border-radius: 5px;
    background: #cfd0d1;
    outline: none;
    margin-right: 10px;
  }
  
  .custom-slider::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    background: #cfd0d1;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .slider-value {
    font-size: 14px;
  }