.leaflet {
    position: relative;
}
.leaflet:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
	width: 0; 
	height: 0; 
    display: block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-top: 7px solid #a4d9ff;
}