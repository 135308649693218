/* table tbody,
table thead {
    display: block;
}

table tbody {
    overflow: auto;
    height: 420px;
}

table {
    /* width: 350px; */
/* can be dynamic */
/* }

th {
    width: 75px;
} */


th:after,
th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;

}


th:after {
    bottom: 0;
    border-bottom: 1px solid #cbcbcb;
}

.pd-15 {
    padding-top: 15px !important
}