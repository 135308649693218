.filters-Container {
  padding: 8px 0px;
  width: max-content;
  display: flex;
}

.filter-scroll {
  overflow-x: scroll;
  cursor: w-resize;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter-label {
  display: flex;
  margin: 2px 4px;
  background-color: rgb(229, 229, 229);
  height: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  color: rgb(48, 48, 48);
  width: max-content;
  font-size: 14px;
}

.filter-key {
  width: fit-content;
}

.filter-scroll::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
.filter-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(194, 191, 191);
  border-radius: 10px;
}

/* Handle */
.filter-scroll::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
  border-radius: 10px;
}

/* Handle on hover */
.filter-scroll::-webkit-scrollbar-thumb:hover {
  background: #b7b4b4;
}

.example-custom-input {
  border-radius: 6px;
  background-color: rgb(222, 252, 252);
  border: 0.5px solid ;
  margin-left: 8px;
  font-size: 12px;
 
}
