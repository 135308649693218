.rankingContainer {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  position: sticky;
  top: 0;
}

.rankingContainer table {
  text-align: center;
}
