main.no-margin {
  margin-left: 0 !important;
}
/* 
#main{
  height: calc(100vh - 60px);
} */

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: thin;  /* Firefox */
}

.logo span {
  font-size: 28px;
  /*  font-weight: 700;*/
  color: #012970;
  /*  font-family: "Nunito", sans-serif;*/
}

html, .MuiTablePagination-selectLabel,
.MuiTablePagination-input,
.MuiTablePagination-displayedRows
{
  font-family: 'Inter', sans-serif !important; 

}

tr {
  font-family: 'Inter', sans-serif; 
  font-size: 12px;
} 


.remove-bgs {
  box-shadow: none !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-input,
.MuiTablePagination-displayedRows{
  font-size:  0.8rem;
}
.inventory-info {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  max-width: 300px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.inventory-info h5 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.inventory-info p {
  margin: 5px 0;
}

.grading-inventory-info {
  border: 1px solid #ddd;
  padding: 10px 10px;
  border-radius: 8px;
  max-width: auto;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.grading-inventory-info h5 {
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.grading-inventory-info p {
  margin: 5px 0;
}

.grading-inventory-info p.pholder{
  font-size: 11px;
}
.change-info {
  display: flex;
  justify-content: space-between;
}

.added {
  color: green;
}

.sold {
  color: red;
}

.icon {
  margin-right: 5px;
}
.marketCellBorder{
  border-bottom: 3px solid #8F70DB;
}

.inventoryCellBorder{
  border-bottom: 3px solid deepskyblue;
}

.font-inter{
  font-family: 'Inter', sans-serif;
}
.grid-container {
  position: relative;
}

.grid-content {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.overlay-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.dropdown-container {
  display: flex;
  gap: 15px; /* Space between the dropdowns */
  margin-bottom: 20px;
  width: 100%; /* Ensure the container takes the full width */
  padding: 1rem;
}

.custom-dropdown {
  width: 50%; /* Each dropdown will take 50% of the container width */
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-dropdown:focus {
  border-color: #007bff;
  background-color: #fff;
}

.custom-dropdown:hover {
  background-color: #f1f1f1;
}

.custom-dropdown:disabled {
  background-color: #eee;
  color: #aaa;
}

.custom-dropdown option {
  background-color: #fff;
  color: #333;
}
/* Style for the "No data available" message */
.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Center vertically with a minimum height */
  text-align: center;
  font-size: 24px; /* Larger font size */
  color: #333;
  padding: 20px; /* Add some padding */
  background-color: #f8f9fa; /* Light background color */
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Ensure the message text is bold */
.no-data-message strong {
  font-weight: bold;
}

#customModal .modal-dialog {
  max-width: 100%; /* Set width */
  margin-top: 3rem;
  margin-left: 3rem;
}

#customModal .modal-dialog .modal-body {
  height: calc(100vh - 150px); /* Adjust height dynamically */
  overflow-y: auto; /* Add scrolling for long content */
}

.grid-shape-size-container {
  margin-top: 20px;
  overflow-x: auto;
}

.grid-shape-size {
  /* width: 100%; */
  border-collapse: collapse;
  table-layout: fixed; /* Ensure fixed column widths */
}

.grid-shape-size th,
.grid-shape-size td {
  width: 80px;
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
  word-wrap: break-word;
}

.grid-shape-size th {
  background-color: #f8f9fa;
}

.hover-info {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.color-clarity-grid table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 20px;
  border-spacing: 0;
}

.color-clarity-grid th,
.color-clarity-grid td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.color-clarity-grid th {
  background-color: #f1f1f1;
}

/* .drilldown {
  margin-top: 20px;
} */

.inventory-sales-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.inventory-sales-table th,
.inventory-sales-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.inventory-sales-table th {
  background-color: #f8f9fa;
}

.inventory-sales-table h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.summaryCardSubSpan{
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.grouped-color-clarity-grid, .color-clarity-grid {
  display: flex;
  flex-wrap: wrap;
}

.grouped-color-clarity-grid .col-md-6, .color-clarity-grid .col-md-6 {
  padding: 10px;
}

.grouped-color-clarity-grid table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.grouped-color-clarity-grid table th,
.grouped-color-clarity-grid table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
}

.grouped-color-clarity-grid table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.controls {
  margin-bottom: 15px;
}

.controls .toggle-btn {
  padding: 5px 15px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.controls .toggle-btn.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
.metric-switch {
  display: flex;
  align-items: center;
}

.metric-switch .radio-label {
  margin-right: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.metric-switch .radio-label input {
  margin-right: 5px;
}

.date-range-picker label {
  margin-right: 15px;
}

.grid-shape-size td {
  position: relative;
}

.grid-shape-size td:hover {
  cursor: pointer;
}

.grid-shape-size td[title] {
  overflow: hidden;
  text-overflow: ellipsis;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}td {
  transition: box-shadow 0.3s, background-color 0.3s, font-weight 0.3s;
}

.highlighted-btn-close{
  border: 2px solid black;
}