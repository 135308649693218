.filterHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
}

.s-e-row {
    margin-left: 20px;

}

.accordion-collapse{
    visibility: initial !important;
}

@media only screen and (max-width: 800px) {
    .filterHeader {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 20px;
        margin-right: 20px;
        /* justify-content: space-between; */
    }

    .s-e-row {
        display: flex;
        margin-top: 20px;

    }

    .mt-10 {
        margin-top: 10px;
    }
}