.searchbar-container {
  border: 0.5px solid #cbcbcb;
  min-height: 32px;
  max-height: 36px;
  justify-self: self-start;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  min-width: 180px;
  width: 50%;
  margin-right: 20px;
}

.searchbar-container input {
  outline: none;
  border: none;
  padding-left: 8px;
  width: 100%;
  font-size: medium;
}

@media only screen and (max-width: 640px) {
  .searchbar-container {
    width: 80%;
  }

  .searchbar-container input {
    text-align: center;
  }

  .searchbar-container input::-webkit-input-placeholder {
    /* Edge */
    text-align: center;
  }

  .searchbar-container input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-align: center;
  }

  .searchbar-container input::placeholder {
    text-align: center;
  }
}
