@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

body, main{
  font-family: 'Inter', sans-serif !important; 

}

html{
  overflow: hidden;
}
.expansion-panel {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.MuiAccordion-root::before{
  display: none;
}

.border-div {
 
  position: relative;
}

.border-div::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px; /* Adjust the border width as needed */
  height: 70%; /* Adjust the height to control where the border starts and ends */
  background-color: #3FAEFF; /* Adjust the border color as needed */
}

.MuiAccordionSummary-content{
  margin:  0px 0px;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #c6c2c7 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c6c2c7;
    border-radius: 19px;
    border: 3px solid #ffffff;
  }

  *::-webkit-scrollbar-thumb:hover {
    
    background: #555;
  }

/* MUI POPOVER */
.custom-shadow{
  
  -webkit-box-shadow: 0px 8px 26px -6px rgba(0,0,0,0.63); 
box-shadow:0px 8px 26px -6px rgba(0,0,0,0.63);
border: #c6c2c7 1px solid;
}

.MuiPaper-root{
  border-radius: 12px !important;
  anti-aliasing: true;
}

p{
  margin: 0;
  padding: 0;
}