.player-div {
    height: 100%;
    width: 100%;
}

.player-div>div {
    height: 100% !important;
    width: 100% !important;
}

.item-display-holder {


    max-width: 500px;
    height: 250px;
    display: flex;
    align-items: start;
    border: 1px solid #c9ccd1;
    border-radius: 8px;
    overflow: hidden;




}

@media only screen and (max-width: 1000px) {

    .item-container {
        display: flex;
        min-width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        /* maxWidth: "500px",
                height: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #c9ccd1",
                alignSelf: "center",
                overflow: "hidden",
                borderRadius: "8px", */

        /* flex-direction: row; */

    }

    .side-item-container {
        min-height: 0px !important;
    }

    .item-display-holder {
        /* width: fit-content; */
        width: 100%;
        max-width: 100%;
    }

    .card-parent-expandable {
        margin: 0px;
        padding: 0px;
    }
}

@media only screen and (min-width: 1024px) {

    .custom-card {
        max-height: 1020px;
    }


}