.table-cs table {
  border-collapse: separate;
  border: 0.5px solid black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 14px;
  border-spacing: 0;
}

.table-cs tr {
  /* border: 0.5px solid black; */
}

.td-edit:hover {
  cursor: url("../../../public/assets/img/write-min.png"), text;
}

.table-cs .editable-input {
  outline: none;
  /* width: 100%; */
  text-align: right;
  /* border: none;
  border-radius: 4px;
  background-color: aliceblue; */
}

.table-cs .editable-input-disabled {
  outline: none;
  width: 100%;
  border: none;
  text-align: right;
  background-color: transparent;
}

.table-cs .editable-input:hover {
  cursor: url("../../../public/assets/img/write-min.png"), auto;
}

.table-cs .editable-input-disabled:hover {
  cursor: url("../../../public/assets/img/edit.png"), text;
}

.txt-al-right {
  text-align: right;
}

.txt-al-cn {
  text-align: center;
}

.table-cs tr td {
  padding: 1px 3px;
  /* border: 0.5px solid black; */
}

.table-cs tr th {
  padding: 5px 5px;
  /* border: 0.5px solid black; */
}

.table-cs tr td {
  table-layout: fixed;
  /* min-width:40px;
    max-width: 60px; */
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.c-header:after {
  bottom: 0;
  border-bottom: 0px solid #cbcbcb !important;
}

.btn-pn {
  border-radius: 7px;
  padding: 0px 4px;
  max-width: 45px;
  max-height: 30px;
  margin-left: 4px;
}

.overflow {
  /* overflow: visible !important; */
}

.overflow div {
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
  display: inline-block;
  width: 100%;
  z-index: 10;
  overflow-x: clip;
}

.overflow:hover div {
  z-index: 1;
  width: auto;
  word-break: break-all;
  text-overflow: unset;
  background-color: #eeecec;
  color: #030202;
  padding: 0px 6px;
  border-radius: 4px;
  position: relative;
}

.anq-wht {
  background-color: antiquewhite;
}

.wht {
  background-color: white;
}

.drksg {
  background-color: darkseagreen;
}

.cloud-dialog {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  padding: 10px;
  border-radius: 5px;
}

.table-row:hover{
  background-color: rgb(209, 228, 245) !important;
}

.editCell-container{
  display:  flex;
  font-size: 12px;
  width: fit-content;

  align-items: center;
}

.editCell-input{
  max-width: 90px;
  margin-right: 10px;
  outline: none;
  border: none;
  padding-top: 4px;
  padding-left: 4px;
}

.custom-popover-basic{
  margin-top: -5px;
}

.svg-holder-editableCell{
  position: absolute;
  overflow: hidden;
  right:2px;
  top:-2px;
  overflow: hidden;
  z-index: 1
}
.cellText{
  border-radius: 12px;
  border: solid 0.5px rgb(201, 199, 199) !important;
  padding: 2px;
  font-size: 11px;
  width: 100%;
  padding-top: 6px;
  padding-left: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  margin: 3px 0px;
  overflow: hidden;
  position: relative;
}

#TTable th:first-child{
  width: 20px !important;
  min-width: 20px
}

.popover-arrow{
  display: none !important;

}

.custom-popover-body{

  padding:  8px !important
}
.editCell-button{
  border: none;
  background-color: rgb(236, 241, 246);
  align-content: center;
  border-radius: 8px;
  margin-left: 10px;
  padding: 4px 6px;
  min-width: 40px;
}

.editableCells{
  /* max-width: 95px; */
  border-radius: 8px;
  border: solid 0.5px rgb(201, 199, 199) !important;
  padding: 2px;
  font-size: 11px;
  width: 100%;
  padding-top: 6px;
  padding-left: 8px;
  padding-right: 12px;
  padding-bottom: 4px;
  /* margin-bottom: 20px; */
  background-color: #f3f1f1;
}

.inputComment-Container{
  width: 250px;
}

@media only screen and (max-width: 640px) {
  .pagination-controls {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .pagination-controls div {
    margin-bottom: 5px;
  }
}


