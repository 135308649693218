.custom-textarea {
    width: 100%;
    height: auto;
    max-height: 6em;
    padding: 10px;
    background-color: rgb(240, 238, 238);
    border: solid 0.5px rgb(201, 199, 199) !important;
    outline: none;
    border-radius: 12px;
    resize: none; /* Disables the resize handle */
    overflow-y: auto; /* Adds scrollbar when content exceeds max-height */
  }

.notes-list{
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
}

.notes-container{
    /* max-width: 250px;
    min-width: 250px; */
    width: 250px;
    max-height: 400px;
    
}
.notes-input-container{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.notes-input-container button{
    margin-top: 10px;
    border-radius: 8px;
    border: none;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(141, 192, 237);
    width: 140px;
}   

