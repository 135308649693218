.rdt_ExpanderRow {
    margin-left: 0px;
}

/* .card-parent-expandable>* {
    word-break: break-all;
    font-size: 0.8rem;
} */


.fs>* {
    font-size: 0.8rem
}

/* .rdt_ExpanderRow {
    margin-left: 0px;
} */

/* @media only screen and (max-width: 1400px) {

    .row>* {
        font-size: 0.8rem;
    }
} */