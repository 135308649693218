.card-container {
  border: 1px solid #cbcbcb;
  min-height: 375px;
  max-height: 375px;
  height: 375px;

  border-radius: 12px;
  padding: 12px;
  margin: 8px;
  min-width: 320px;
  max-width: 320px;
  background-color: white;
  font-size: 0.9rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-media {
  min-width: 100%;
  min-height: 70%;
  height: 70%;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  max-height: 25%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-details-row {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  /* padding-bottom: 5px; */
}

.card-details-row label {
  width: 50%;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

label {
  margin-bottom: 0;
}

.card-details-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  /* border-top: 1px solid #cbcbcb;  */
  padding-top: 5px;
  /* padding-bottom: 5px; */
}

.card-details-col label {
  font-weight: bold;
}

.card-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(194, 191, 191);
  border-radius: 10px;
}

/* Handle */
.card-container::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202);
  border-radius: 10px;
}

/* Handle on hover */
.card-container::-webkit-scrollbar-thumb:hover {
  background: #b7b4b4;
}

.card-key {
  text-overflow: ellipsis;
}
